import './Home.css';

import cover from '../images/Front Cover of Book.jpg';
import buyButton from '../images/buy-now-amazon.jpg';
import pubLogo from '../images/pub-logo.jpg';

import { Link } from 'react-router-dom';
import HOC from './HOC';
import Title from './Title';
import VideoElem from './VideoElem';

const Downloads = () => {
  return (
    <div className='downloads'>
      <div className='download-contain'>
        <a href={`${process.env.PUBLIC_URL}/30ThingsSampleChapter.pdf`} target="_blank" rel="noopener noreferrer">Sample Chapter</a>
      </div>
      <div className='download-contain'>
        <a href={`${process.env.PUBLIC_URL}/30ThingsTableofContents.pdf`} target="_blank" rel="noopener noreferrer">Table of Contents</a>
      </div>
      <div className='download-contain'>
        <Link to='/reviews'>Reviews</Link>
      </div>
    </div>
  )
}

const AuthorNote = () => {
  return (
    <div className='note-container'>
      <h2>Practical advice for living well</h2>
      <p>They say “It takes a village.”</p>
      <p>And yet today it can be difficult, if not impossible, for others to penetrate the ‘boundaries’ that we all seem to build up around ourselves.</p>
      <p>“No thanks. I can figure it out by myself.”</p>
      <p>30 Things invites you to take a step back from that kind of individualistic mindset and consider a different model for your life.</p>
      <p>One that embraces a larger community.</p>
      <p>One that doesn’t just value advice from others, but actively seeks out such. help.</p>
      <p>One that is open-minded, attentive and committed to positive change.</p>
      <p>You won’t find anything too ‘preachy’ in these pages. Nor is 30 Things a book filled with lofty aspirations, pious platitudes or impossible goals.</p>
      <p>Bill Schaberg simply presents thirty different pieces of worthwhile advice that he has been given over the years, tells how each one first came to him, and then relates how he tried to incorporate them into his life.</p>
      <p>30 Things is a book of practical advice. Advice that – if actually put into practice – can help promote the book’s promised goal of “living well”.</p>
      <p>At least, that’s been the author’s experience.</p>
      <p>Hence, 30 Things... where he shares advice that he sincerely hopes might work for you too!</p>
    </div>
  )
}

const BookCover = () => {
  return (
    <div className='cover-contain'>
      <img src={cover} alt='cover' id='book-cover'/>
    </div>
  )
}

const PurchaseSection = () => {
  return (
    <div className='subcover'>
      <span>Available in Hardback, Paperback, and eBook versions</span>
      <a href='https://www.amazon.com/30-Things-Practical-Advice-Living/dp/0988115735/' target='_blank' rel="noreferrer noopener">
        <img src={buyButton} alt='buy' />
      </a>
    </div>
  )
}

export default function Home() {
    return (
        <div className="Home">
          <HOC>
            <div id="body">
              <Title main='true'>
                <h1>30 Things</h1>
                <h2>Practical Advice for Living Well</h2>
              </Title>
              <Downloads />
              <VideoElem title="Writing the Big Book | The History of A.A. " link="https://www.youtube.com/embed/rGLGE1aa7Ak?si=x2qgfssOHqPVD7fZ" id='home-page'/>
              <BookCover />
              <PurchaseSection />
              <AuthorNote />
              <div className='pub'>
                <p>Buy direct from</p>
                <p><b>Rebellion Dogs</b> for bundles</p>
                <p><i>(physical book + eBook at a discount)</i></p>
                <p>or multiple copies</p>
                <a href='https://rebelliondogspublishing.com/30things' target='_blank' rel="noreferrer noopener"><img src={pubLogo} alt='pub-logo' id='pub-logo'/></a>
              </div>
            </div>
          </HOC>
        </div>
    )
}